<template>
    <div>
        <el-form ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="状态：" prop="page" class="pageSelect" require>
                <el-switch v-model="status" active-text="开启" inactive-text="关闭"  inactive-value="PAUSED" active-value="ACTIVE"> </el-switch>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:['statusInfo'],
    data() {
        return {
            // statusMsg:{status:''}
        }
    },
    computed:{
        status:{
            get(){
                return this.statusInfo
            },
            set(v){
                console.log(v);
                this.$emit('getStatusMsg',v)
            }
        }
    },
    methods: {
        // getStatusMsg(v){
        //     this.$emit('getStatusMsg',v)
        // }
    },
    watch: {
        // statusInfo:{
        //     handler(){
        //         console.log('aaaaaaaaaaaaaaaaaaa');
        //         this.$set(this.statusMsg,'status',this.statusInfo)
        //     },
        //     immediate:true
        // }
    }
}
</script>
<style lang="scss" scoped>
    
</style>